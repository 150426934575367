<!--
 * @Description: 
 * @Author: huacong
 * @Date: 2021-09-02 13:15:40
 * @LastEditTime: 2021-09-02 16:04:37
 * @LastEditors: huacong
-->
<template>
  <div>
    <div class="nosebox">
      <section>
        <a class="logo" @click="goHome">
          <img :src="logo" alt="logo" />
        </a>
      </section>
    </div>
    <section>
      <div class="textcentent" :style="'height:' + bheight + 'px'">
        <p class="line1">尊敬的用户：</p>
        <p class="line2">
          您输入邮件中的口令
          <el-input
            style="width:190px"
            placeholder="口令输入在这里"
            v-model="kl"
          ></el-input>
          ， 点击

          <el-button type="primary" size="mini" @click="postAuth">
            确认
          </el-button>
          即可完成认证!
        </p>
      </div>
    </section>
    <el-image :src="topbanner" style="width:100%;height:130px"></el-image>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logo: require("@/assets/img/logo.png"),
      topbanner: require("@/assets/img/topbanner01.png"),
      bheight: 200,
      kl: "",
    };
  },
  methods: {
    goLogin() {
      this.$router.push({ path: "/Login" });
    },
    goHome() {
      this.$router.push({ path: "/Home/index" });
    },
    setbodyHeight() {
      let bodyHeight = document.documentElement.clientHeight;
      console.log(bodyHeight);
      this.bheight = bodyHeight - 375;
    },
    postAuth() {
      let uid = this.$route.query.uid;
      let email = this.$route.query.eamail;
      if (!this.kl) {
        return this.$message.warning("请输入口令！");
      }
      this.$http
        .post("index/auth/authemail", {
          uid: uid,
          eamail: email,
          auth: 2,
          kl: this.kl,
        })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.$message.success("认证成功,现在回到首页！");
            setTimeout(() => {
              this.goHome();
            }, 1900);
          }
        });
    },
  },
  mounted() {
    this.setbodyHeight();
  },
};
</script>
<style scoped>
.nosebox {
  background-color: #131d28;
}
section .logo {
  margin-top: 5px;
}
.nosebox section {
  height: 80px;
  display: flex;
  align-items: center;
}
.loginBtn {
  float: right;
  padding: 12px 20px;
  background: #2573f1;
  color: #fff;
  border-radius: 50px;
  margin-left: auto;
}
.loginBtn span {
  cursor: pointer;
}
.note {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
}
.textcentent {
  padding: 40px;
  margin: 40px;
  height: 1500px;
  border-radius: 10px;
  box-shadow: 1px 0px 12px 6px #eee;
}
.line1 {
  font-size: 36px;
  font-weight: bold;
}
.line2 {
  padding: 15px 72px;
  font-size: 26px;
}
.line2 span {
  color: #4892ec;
  cursor: pointer;
}
</style>
